import React from 'react';
export const Cart=(props)=>{
    const {cartData, updateCart}=props;
    const imagePath='/images/products/';
    const modifyCart = (ops, productId) => {
        const cachedItems= localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")):'';
        var itemIndex='';
        cachedItems.find((o, i) => {
            if (o.product.id === productId) {
                if(ops==='inc') {
                    cachedItems[i].quantity=cachedItems[i].quantity+1;
                }else if(ops==='dec') {
                    if(cachedItems[i].quantity>1){
                        cachedItems[i].quantity=cachedItems[i].quantity-1;
                    }
                }
                itemIndex=i;
                return true; // stop searching
            }
        });
        if(ops==='del'){
            cachedItems.splice(itemIndex,1);
        }
        localStorage.setItem("cartItems",JSON.stringify(cachedItems));
        updateCart();
    }
    return(
        <section class="h-100">
  <div class="container h-100 py-5">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-10">

        <div class="d-flex justify-content-between align-items-center mb-4">
          <h3 class="fw-normal mb-0 text-black">Shopping Cart</h3>
        </div>
        { cartData?.length > 0 ?
                    cartData.map((item, index) => {
                        return(
                            <div class="card rounded-3 mb-4">
                            <div class="card-body p-4">
                              <div class="row d-flex justify-content-between align-items-center">
                                <div class="col-md-2 col-lg-2 col-xl-2">
                                  <img
                                    src={`${imagePath}${item.product.imagename}`}
                                    class="img-fluid rounded-3" alt="Cotton T-shirt"/>
                                </div>
                                <div class="col-md-3 col-lg-3 col-xl-3">
                                  <p class="lead fw-normal mb-2">{item.product.productname}</p>
                                  <p><span class="text-muted">{item.product.category}</span></p>
                                </div>
                                <div class="col-md-3 col-lg-3 col-xl-2 d-flex">
                                  <button data-mdb-button-init data-mdb-ripple-init class="btn btn-link px-2"
                                    onClick={()=>modifyCart('dec',item.product.id)}>
                                    <i class="fas fa-minus"></i>
                                  </button>
                  
                                  <input id="form1" min="0" name="quantity" value={item.quantity} type="number"
                                    class="form-control form-control-sm" />
                  
                                  <button data-mdb-button-init data-mdb-ripple-init class="btn btn-link px-2"
                                    onClick={()=>modifyCart('inc',item.product.id)}>
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </div>
                                <div class="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                                  <h5 class="mb-0"></h5>
                                </div>
                                <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                                  <div onClick={()=>modifyCart('del',item.product.id)} class="text-danger"><i class="fas fa-trash fa-lg"></i></div>
                                </div>
                              </div>
                            </div>
                          </div>
                    )})
                    :
                    <div class="card rounded-3 mb-4">
                        <div class="card-body p-4">
                            <div class="row d-flex justify-content-between align-items-center">
                            No items in your cart
                            </div>
                        </div>
                    </div>
                    }
        
       

       {/*
        <div class="card mb-4">
          <div class="card-body p-4 d-flex flex-row">
            <div data-mdb-input-init class="form-outline flex-fill">
              <input type="text" id="form1" class="form-control form-control-lg" />
              <label class="form-label" for="form1">Discound code</label>
            </div>
            <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-outline-warning btn-lg ms-3">Apply</button>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-warning btn-block btn-lg">Proceed to Pay</button>
          </div>
        </div>
                */}

      </div>
    </div>
  </div>
</section>
    )
}