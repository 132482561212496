import React from 'react';
export const About=()=>{
    return(
        <div className="container text-center about-conatiner">
            <h4 className="pop-light">About Washingtonia</h4>
            <h2 className="pop-bold">Plants, planters and other related products</h2>
            <p className="pt-2 pop-regular abot-text-color content-align">
            We have pleasure in introducing our company Washingtonian Nursery located in Qatar is a service oriented 
and professionally managed company. We are one of the Premiums privately-owned nurseries in Qatar. Our 
production facility currently cultivates more then 130-150 thousand plants (over 200-250 species) from 16-
20 countries approximately. which are from China, Thailand, Malaysia, India, Pakistan, Holland, Italy, Spain, 
Germany, Portugal, Costa Rica, Turkey, Greece, Argentina, Mexico, Brazil, and Chile.
            </p>
        </div>
    )
}