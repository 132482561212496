import { Header } from '../header';
import { Home } from '../home';
import { Footer } from'../footer';
import { About } from '../About';
import { Services} from '../Services';
import IndoorContainer from '../../containers/indoor/Index';
import OutdoorContainer from '../../containers/outdoor/Index';
import PotsContainer from '../../containers/pots/Index';
import LandscapingworkContainer from '../../containers/landscape/Index';
import OtherproductsContainer from '../../containers/other/Index';
import ProjectsContainer from '../../containers/projects/Index';
import { Landscaping } from '../Landscaping/Landscaping';
import CategoriesContainer from '../../containers/categories/Categories';
import CategoriesAllContainer from '../../containers/categories/Categories copy';
import ProductdetailsContainer from '../../containers/productdetails/productdetails';
import SearchContainer from '../../containers/search/Index';
import CartContainer from '../../containers/cart/Index';
import { Privacy } from '../Privacy';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

export const Layout=(props)=>{
    const location = useLocation();
    const { pathname } = location;
    const [isLoading, setLoader] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [pathname])
    document.onreadystatechange=()=> {
        if (document.readyState !== 'complete') {
            document.querySelector("body").style.visibility = "hidden";
        } else {
            document.querySelector("body").style.visibility = "visible";
            setLoader(false);
        }
    };
    function checkFirstVisit() {
        if(document.cookie.indexOf('mycookie')==-1) {
          // The cookie doesn't exist. Create it now
          //console.log("refreshed");
          document.cookie = 'mycookie=1';
          navigate(pathname)
        }
        else {
          // Not the first visit, so alert
          //console.log("Not the first visit");
        }
    }
    checkFirstVisit();
    return(
        <>
            {isLoading && <div id="loader" className="center"></div>}
            <Header></Header>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="About" element={<About/>}></Route>
                <Route path="Indoor" element={<IndoorContainer/>}></Route>
                <Route path="Outdoor" element={<OutdoorContainer/>}></Route>
                <Route path="Pots" element={<PotsContainer/>}></Route>
                <Route path="Landscaping" element={<Landscaping/>}></Route>
                <Route path="Landscapingwork" element={<LandscapingworkContainer/>}></Route>
                <Route path="Otherproducts" element={<OtherproductsContainer/>}></Route>
                <Route path="Projects" element={<ProjectsContainer/>}></Route>
                <Route path="Privacy" element={<Privacy/>}></Route>
                <Route path="Categories/:ids" element={<CategoriesContainer/>}></Route>
                <Route path="Categoriesall" element={<CategoriesAllContainer/>}></Route>
                <Route path="Product/:id" element={<ProductdetailsContainer/>}></Route>
                <Route path="Search" element={<SearchContainer/>}></Route>
                <Route path="Services" element={<Services/>}></Route>
                <Route path="Cart" element={<CartContainer/>}></Route>
            </Routes>
            <Footer></Footer>
        </>
    )
}