import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import PopUpContainer from '../containers/popup'
export const Rows=(props)=>{
    const {product}=props;
    const imagePath='/images/products/';
    const navigate = useNavigate();
    const showDetails=()=>{
        navigate(`/Product/${product.id}`)
    }
    return(
        <div className="productframe">
        <div className="productimagecontainer" onClick={()=>showDetails()}>
            <div className="pimgcover">
                <div className="productimage"><img src={`${imagePath}${product.imagename}`}/>
                </div>
            </div>
        </div>
        <div className="pricebox">
            <div>
                <p id="prodname" className='pop-bold'>{product.productname}</p>
                <h6 className="pt-2 pb-3 product-category">CAT: <span className='product-category-text'>{product.category}</span></h6>
                <h6 className="pb-3 product-sub-category">SUBCAT: <span className='product-category-text'>{product.subcategory}</span></h6>
                { product.instock === 'yes' ?
                    <div className="d-flex">
                        <span className='product-stock'>in stock</span><span className="bg-light product-dimension">H: {product.height} W: {product.width}</span>
                        <PopUpContainer product={product}></PopUpContainer>
                    </div>
                    :
                    <div className="d-flex">
                        <span className='product-out-sock'>Out of stock</span><span className="bg-light product-dimension">H: {product.height} W: {product.width}</span>
                    </div>
                }                     
            </div>
        </div>
    </div>

    )
}