
import React, { useState, useEffect } from 'react';
import { Rows } from './Rows';
import SortContainer from '../containers/sort/Index';
export const Indoor=(props)=>{
    const [sortedProducts, setSortedProducts]=useState([])
    useEffect(() => {
        document.title = 'Washingtonia || Indoor';
        async function fetchData() {
            const payload={
                category:'indoor'
            }
            await props.fetchProductsData(payload);
        }
        fetchData();
      }, []);
    const filterOpen=()=>{
        document.getElementById("filtercontainer").style.marginLeft="0px";
    }
    const productsArray = props.productData ? props.productData : [] 
    return(
        <>
            <div id="topbanner" className="col-md-12 " style={{position:'relative'}}>
                <div className="col-md-12 inner-banner">
                    <div id="top1zoom" className='indoor-banner-image'>
                        <div className="overlay"></div>
                    </div>
                    <div id="topbannerheadings">
                        <div className="col-md-8 text-center p-2 ps-4 pe-4">
                            <h3 className="inner-heading">INDOOR PLANTS</h3>
                            <p style={{color:'white'}}>Indoorplants can offer minor recovery from stress when instituted as part of an environment. The presence of a houseplant in an office setting can also increase productivity!</p>
                        </div>
                    </div>
                    <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>                             
                    <div className="inner-offer">
                        <div id="topviewtab1" style={{cursor:'pointer'}}>
                            <h4 style={{color:'white'}}>GET THIS OFFER NOW</h4>
                            <div className="underlineaniation1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-4">
                <div className="row">
                    <SortContainer category='indoor'></SortContainer>
                </div>
                <div id="content">
                    { productsArray?.length > 0 ?
                    productsArray.map((product, index) => {
                        return(
                       <Rows product={product} key={index}></Rows>
                    )})
                    :
                    <div className="d-flex justify-content-center align-items-center product-no-records">No results found</div>
                    }
                    
                </div>                                                
            </div>

        </>
    )
}