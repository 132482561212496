import React from 'react';
import { useNavigate } from "react-router-dom";
export const MoreCategories=()=>{
    const navigate = useNavigate();
    const showCategory=(category)=>{
        navigate(`/Categories/${category}`)
    }
    return(
        <div style={{position:'relative'}} className="pb-5">
            <h1 className="pt-5 pb-3 text-center pop-bold">More Categories</h1>
            <div className="container">
                <div onClick={()=>showCategory('groundcover')} id="cat1">
                    <div className="category-image-container">
                        <div id="catimage1" className="category-image more-category-image1">
                            <div className="overlay"></div>
                        </div>
                        <div className="category-text"><h3 className="text-center category-text-color">GROUND COVERS</h3></div>
                    </div>
                </div>
                <div onClick={()=>showCategory('pebbles')} id="cat2">
                    <div className="category-image-container">
                        <div id="catimage2" className="category-image more-category-image2">
                            <div className="overlay"></div>
                        </div>
                        <div className="category-text"><h3 className="text-center category-text-color">PEBBLES</h3></div>
                    </div>
                </div>
                <div onClick={()=>showCategory('dripline')} id="cat3">
                    <div className="category-image-container">
                        <div id="catimage3" className="category-image more-category-image3">
                            <div className="overlay"></div>
                        </div>
                        <div className="category-text"><h3 className="text-center category-text-color">DRIPLINE</h3></div>
                    </div>
                </div>
                <div onClick={()=>showCategory('barkmulch')} id="cat4">
                    <div className="category-image-container">
                        <div id="catimage4" className="category-image more-category-image4">
                            <div className="overlay"></div>
                        </div>
                        <div className="category-text"><h3 className="text-center category-text-color">BARKMULCH</h3></div>
                    </div>
                </div>
                <div onClick={()=>showCategory('verticalgarden')} id="cat5">
                    <div className="category-image-container">
                        <div id="catimage5" className="category-image more-category-image5">
                            <div className="overlay"></div>
                        </div>
                        <div className="category-text"><h3 className="text-center category-text-color">VERTICAL GARDEN</h3></div>
                    </div>
                </div>
                <div onClick={()=>showCategory('oher')} id="cat6">
                    <div className="border category-image-container">
                        <div className="category-text"><div className="btn btn-warning rounded category-text-color">View All</div></div>
                    </div>
                </div>                     
            </div>
        </div>
    )
}