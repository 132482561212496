
import React, { useState, useEffect } from 'react';
import { Filter } from './Filter';
import { Rows } from './Rows';
import SortContainer from '../containers/sort/Index';
export const Outdoor=(props)=>{
    useEffect(() => {
        document.title = 'Washingtonia || Outdoor';
        async function fetchData() {
            const payload={
                category:'outdoor'
            }
            await props.fetchProductsData(payload);
        }
        fetchData();
      }, []);
    const filterOpen=()=>{
        document.getElementById("filtercontainer").style.marginLeft="0px";
    }
    const productsArray = props.productData ? props.productData : [] 
    return(
        <>
            <div id="topbanner" className="col-md-12 " style={{position:'relative'}}>
                <div className="col-md-12 inner-banner">
                    <div id="top1zoom" className='outdoor-banner-image'>
                        <div className="overlay"></div>
                    </div>
                    <div id="topbannerheadings">
                        <div className="col-md-8 text-center p-2 ps-4 pe-4">
                            <h3 className="inner-heading">OUTDOOR PLANTS</h3>
                            <p style={{color:'white'}}>Plants that need low maintenance and grow up easily on the outside are known as Outdoor plants. Outdoor plants give us oxygen and absorb carbon dioxide during the photosynthesis process, but also reduce stress, anxiety, and tiredness and give us relaxation.!</p>
                        </div>
                    </div>
                    <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>
                </div>
            </div>
            <div class="container pt-4">
                <div class="row">
                    <SortContainer category='outdoor'></SortContainer>
                </div>
                <div id="content">
                    { productsArray?.length > 0 ?
                    productsArray.map((product, index) => {
                        return(
                       <Rows product={product} index={index}></Rows>
                    )})
                    :
                    <div class="d-flex justify-content-center align-items-center product-no-records">No results found</div>
                    }
                    
                </div>                                                
            </div>

        </>
    )
}