import React from 'react';
import { Discalimer } from "./Discalimer";
import { CookieConsent } from "./CookieConsent";
import { Link } from 'react-router-dom';
import { FormLabel } from 'react-bootstrap';
export const Footer=()=>{
    return(
        <>
            <div className="container border-top">
                <div className="row">
                    <div className="col-md-3 pt-5">                    
                        <ul>
                            <li className="nav-link pb-4"><h6 className="p-1 pop-bold">Washingtonia</h6></li>
                            <li className="nav-link">
                                <Link to="/About" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Who we are</label>
                                </div></Link>
                            </li>                        
                            <li className="nav-link">
                                <Link to="/Privacy" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Privacy policy</label>
                                </div></Link>
                            </li>                       
                        </ul>
                    </div>                
                    <div className="col-md-3 pt-5">                    
                        <ul>
                            <li className="nav-link pb-4"><h6 className="p-1 pop-bold">Links</h6></li>
                            <li className="nav-link">
                                <Link to="/" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Home</label>
                                </div></Link>
                            </li>
                            <li className="nav-link">
                                <Link to="/Indoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Indoor Plants</label>
                                </div></Link>
                            </li>
                            <li className="nav-link">
                                <Link to="/Outdoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Outdoor</label>
                                </div></Link>
                            </li>
                            <li className="nav-link">
                                <Link to="/Pots" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Pots</label>
                                </div></Link>
                            </li>
                            <li className="nav-link">
                                <Link to="/Projects" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="nav-link">
                                    <label className="p-1 pop-regular abot-text-color">Projects</label>
                                </div></Link>
                            </li>                        
                        </ul>
                    </div>
                    <div className="col-md-3 pt-5">                    
                        <ul>
                            <li className="nav-link pb-4"><h6 className="p-1 ps-0 pop-bold">Address</h6></li>
                            <label className="pop-regular abot-text-color">Washingtonia Nursery WLL</label>
                            <label className="pop-regular abot-text-color">P.O Box: 6819 Doha,</label>
                            <label className="pop-regular abot-text-color">Qatar</label>  
                        </ul>
                    </div>
                    <div className="col-md-3 pt-5">                    
                        <ul>
                            <li className="nav-link pb-4"><h6 className="p-1 ps-0 pop-bold">Contact details</h6></li>
                            <li className="nav-link"><label className="pb-0 pop-regular abot-text-color"><span className="footer-phone">Phone</span></label></li>
                            <li className="nav-link"><label className="p-1 pt-0 pop-regular abot-text-color">+974-44134986</label></li>
                            <li className="nav-link"><label className="p-1 pb-0 pop-regular abot-text-color"><span className="footer-phone">Email</span></label></li>
                            <li className="nav-link"><label className="p-1 pt-0 footer-email">washingtonia.nursery@gmail.com</label></li>
                        </ul>
                    </div>
                </div>            
            </div>
            <Discalimer></Discalimer>
            <CookieConsent></CookieConsent>
        </>
    )
}