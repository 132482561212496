
import React, { useState, useEffect } from 'react';
import { Filter } from './Filter';
import { Rows } from './Rows';
import SortContainer from '../containers/sort/Index';
export const Search=(props)=>{
    useEffect(() => {
        document.title = 'Washingtonia || Search';
      }, [props.productData]);
    const filterOpen=()=>{
        document.getElementById("filtercontainer").style.marginLeft="0px";
    }
    const productsArray = props.productData ? props.productData : [] 
    return(
        <>
            <div class="container pt-4">
                <div class="row">
                    <SortContainer></SortContainer>
                </div>
                <div class="container ps-4">
                    <h6>Search results</h6>
                </div>
                <div id="content">
                    { productsArray?.length > 0 ?
                    productsArray.map((product, index) => {
                        return(
                       <Rows product={product} index={index}></Rows>
                    )})
                    :
                    <div class="d-flex justify-content-center align-items-center product-no-records">No results found</div>
                    }
                    
                </div>                                                
            </div>

        </>
    )
}