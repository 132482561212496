
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function fullscreen(names){
    document.getElementById("fullscreen").innerHTML="<span id='fullclose' onClick='fullscreenclose()'>CLOSE</span><img src='images/landscaping/"+names+"' style='height:90vh'>";
    document.getElementById("fullscreen").style.display="flex";
}
const LandscapItems=(props)=>{
    const imagePath=`../images/landscaping/${props.product?.imagename}`;
    return(
        <div onClick={()=>fullscreen(props.product?.imagename)} className="landscape col-md-3 bg-success">
            <div className="landscapein md-12 landscape-items" style={{ backgroundImage:`url(${imagePath})`}}>
            </div>
        </div>
    )
}

export const Landscapingwork=(props)=>{
    const navigate = useNavigate();
      useEffect(() => {
        document.title = 'Washingtonia || Landscaping';
        async function fetchData() {
            const payload={
                category:'landscape'
            }
            await props.fetchProductsData(payload);
        }
        fetchData();
      }, []);
    const productsArray = props.productData ? props.productData : [];
    return(
        <>
        <div id="fullscreen" className='landscapework'></div>
        <div className="container mb-5">
            <h3 className="pt-5 mb-4"><span className='our-landscape-works'>OUR LANDSCAPING WORKS</span></h3>
            <div className="p-0">
                { productsArray?.length > 0 ?
                    productsArray.map((product, index) => {
                        return(
                       <LandscapItems product={product} index={index}></LandscapItems>
                    )})
                    :
                    ''
                }
            </div>
        </div>
        </>
    )
}