import React from 'react';
import { Link } from 'react-router-dom';
export const CookieConsent=()=>{
    const setCookie=(cname, cvalue, exdays)=> {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // Delete cookie
    const deleteCookie=(cname)=> {
        const d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=;" + expires + ";path=/";
    }

    // Read cookie
    const getCookie=(cname)=> {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Set cookie consent
    const acceptCookieConsent=()=>{
        deleteCookie('user_cookie_consent');
        setCookie('user_cookie_consent', 1, 30);
        if(document.getElementById("cookieNotice")){
            document.getElementById("cookieNotice").style.display = "none";
        }
    }
    let cookie_consent = getCookie("user_cookie_consent");
    if(document.getElementById("cookieNotice")){
        if(cookie_consent != ""){
            document.getElementById("cookieNotice").style.display = "none";
        }else{
            document.getElementById("cookieNotice").style.display = "block";
        }
    }
    return(
        <div id="cookieNotice" className="fixed-bottom light display-right p-4 text-light cookie-notice">
            <div id="closeIcon" style={{display:'none'}}>
            </div>
            <div className="title-wrap">
                <h4 className="text-dark">We use Cookie!!</h4>
            </div>
            <div className="content-wrap">
                <div className="msg-wrap">
                    <div className="row">
                        <div className="col-md-10">
                        <p className="text-dark cookie-policy">This website uses cookies or similar technologies, to enhance your browsing experience and provide personalized recommendations. By continuing to use our website, you agree to our  <Link to="/Privacy" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><label className="cookie-policy-link" >Privacy Policy</label></Link></p>
                        </div>
                        <div className="col-md-2">
                        <div className="btn-wrap">
                        <button className="btn border ms-auto me-auto" onClick={acceptCookieConsent}>Accept</button>
                    </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}