import React, { useState, useEffect } from 'react';
import logo from '../../images/logo.png';
import { useNavigate } from "react-router-dom";
export const Search=(props)=>{
    const [searchParam, setSearchParam]=useState('');
    const navigate = useNavigate();
    useEffect(() => {
        props.updateCart();
      }, []);
    async function searchData() {
        const payload={
            searchKey:searchParam
        }
        await props.searchProduct(payload);
        navigate('/Search');
    }
    const searchOnEnter=(e)=>{
        if (e.key === "Enter") { 
            searchData();
        }
    }
    const cachedItems = localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")):'';
    const cartItemsCount = props.cartData?.length || 0;
    return(
        <nav className="navbar navbar-expand-sm navbar-light d-flex justify-content-between">            
            <a className="navbar-brand" href="/"><img src={logo} style={{width:170+'px'}}/></a>
                <div className="input-group mt-2 mb-2 search-form">
                    <input id="search" type="text" name="searchterms" value={searchParam}  onKeyDown={(e) => searchOnEnter(e)} onChange={e =>setSearchParam(e.target.value)} className="form-control me-1 ps-4 search-input" placeholder="Search" required/>
                    <button type="button" className="btn serch-button" onClick={()=>searchData()}>
                            SEARCH
                    </button>
                </div>
            <div className="border-start border-end ps-4 pe-4 phone-number" >Ph:+974-44134986</div>
            <div>
                <a href="https://www.facebook.com/" target="_blank" className="nav-item text-black" style={{cursor:'pointer'}}><i className="fa-brands fa-square-facebook fa-lg p-3 ps-0"></i></a>
                <a href="https://x.com/" target="_blank" className="nav-item text-black" style={{cursor:'pointer'}}><i className="fa-brands fa-twitter fa-lg p-3 ps-0"></i></a>
                <a href="https://www.youtube.com/" target="_blank" className="nav-item text-black" style={{cursor:'pointer'}}><i className="fa-brands fa-youtube fa-lg p-3 ps-0"></i></a>
                <a href="https://www.instagram.com/" target="_blank" className="nav-item text-black" style={{cursor:'pointer'}}><i className="fa-brands fa-instagram fa-lg p-3 ps-0 pe-0"></i></a>
                
            </div>
            <span id="cart" className="ms-auto cart-icon-header-span" onClick={()=>navigate('/Cart')}>
                <a className="nav-item text-light" style={{cursor:'pointer'}}><i className="fas fa-shopping-cart fa-lg"><sup id="cartnum1" className="badge bg-danger cart-icon-header" >{cartItemsCount}</sup></i></a>
            </span>
        </nav>     
    )
}