import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCT,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_ERROR,
    SEARCH_PRODUCT,
    SORT_PRODUCTS
} from './Constants';

export function fetchProducts(data) {
    return {
      type: FETCH_PRODUCTS,
      data: data,
    };
  }
  export function fetchProductsSuccess(data) {
    return {
      type: FETCH_PRODUCTS_SUCCESS,
      data: data,
    };
  }
  export function fetchProductsError(error) {
    return {
      type: FETCH_PRODUCTS_ERROR,
      error:error,
    };
  }
  export function fetchProduct(data) {
    return {
      type: FETCH_PRODUCT,
      data: data,
    };
  }
  export function fetchProductSuccess(data) {
    return {
      type: FETCH_PRODUCT_SUCCESS,
      data: data,
    };
  }
  export function fetchProductError(error) {
    return {
      type: FETCH_PRODUCT_ERROR,
      error:error,
    };
  }
  export function searchProduct(data) {
    return {
      type: SEARCH_PRODUCT,
      data: data,
    };
  }
  export function sortProducts(data) {
    return {
      type: SORT_PRODUCTS,
      data: data,
    };
  }