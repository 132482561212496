
import React, { useState, useEffect } from 'react';
import { Filter } from '../Filter';
import { Rows } from '../Rows';
import { useParams } from 'react-router-dom';
import SortContainer from '../../containers/sort/Index';
export const Categories=(props)=>{
    const { ids } = useParams();
    useEffect(() => {
        document.title = 'Washingtonia || Categories';
        async function fetchData() {
            const payload={
                page:'category'
            }
            await props.fetchProductsData(payload);
        }
        fetchData();
      }, []);
    const filterOpen=()=>{
        document.getElementById("filtercontainer").style.marginLeft="0px";
    }
    const productsArray = props.productData ? props.productData : [] 
    const product=ids;
    const imagePath=`../images/productpages/${product}.jpg`;
    
    return(
        <>
            <div id="topbanner" className="col-md-12 " style={{position:'relative'}}>
                <div className="col-md-12 inner-banner">
                    <div id="top1zoom" className='category-banner-image' style={{ backgroundImage:`url(${imagePath})` }}>
                        <div className="overlay"></div>
                    </div>
                    <div id="topbannerheadings">
                        <div className="col-md-8 text-center p-2 ps-4 pe-4">
                            <h3 className="inner-heading">{product.toUpperCase()}</h3>
                        </div>
                    </div>
                    <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>                             
                </div>
            </div>
            <div className="container pt-4">
                <div className="row">
                    <SortContainer></SortContainer>
                </div>
                <div id="content">
                    { productsArray?.length > 0 ?
                    Object.keys(productsArray).map((product, index) => {
                        return(
                       <Rows product={productsArray[product]} index={index}></Rows>
                    )})
                    :
                    <div className="d-flex justify-content-center align-items-center product-no-records">No results found</div>
                    }
                    
                </div>                                                
            </div>

        </>
    )
}