import React from 'react';
import { HomeBanner } from './HomeBanner';
import { Category } from './Category';
import { Content } from './Content';
import { About } from './About';
import { MoreCategories } from './MoreCategories';
export const Home=()=>{
    return(
        <>
            <HomeBanner></HomeBanner>
            <Category></Category>
            <Content></Content>
            <About></About>
            <MoreCategories></MoreCategories>
        </>
    )
}