import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import logo from '../../images/logo.png'

function useOutsideAlerter(ref) {
    useEffect(() => {
   
      // Function for click event
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            if(!event.target.classList.contains("menuresponse")){
                document.getElementById("rolldownmenucontainer").style.marginLeft="-350px";
            }
        }
      }
   
      // Adding click event listener
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);
  }
export const Rolldown=()=>{
    const navigate = useNavigate();
    const cartState = useSelector((state) => state.cartReducer);
    const cartItemsCount= cartState?.cartData?.length || 0;
    const box = useRef(null);
    useOutsideAlerter(box);
    const rollmenuopen=()=>{
        document.getElementById("rolldownmenucontainer").style.marginLeft="0px";
    }
    const rollmenuclose=()=>{
        document.getElementById("rolldownmenucontainer").style.marginLeft="-350px";
    }
    return(
        <>
            <div id="rolldownmenucontainer" className="d-flex" ref={box}>
                <div className="rolldown-menu"></div>
                <div style={{width:80 + '%'}}>
                    <ul className="navbar-nav ps-3 pt-5">
                        <li className="nav-item">
                            <Link to="/" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Home</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/About" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">About Us</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Indoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Indoor Plants</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Outdoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Outdoor Plants</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Pots" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Pots</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Landscaping" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Landscaping</div></Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/Projects" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" style={{cursor:'pointer'}} className="nav-link">Projects</div></Link>
                        </li>
                        <li onClick={rollmenuclose} className="menuresponse bg-warning p-1 text-center mt-5" style={{width:100 + 'px'}}>
                            CLOSE
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container-fluid shadow p-3 align-items-center justify-content-between" id="navbarboxrolldown">
            <div className="nav-item">
                <i onClick={rollmenuopen} className="menuresponse fa-solid fa-bars fa-2xl"></i>
            </div>
            <div>
                <img onClick={()=>navigate('/')} src={logo} className="rolldown-header-logo"/>
            </div>
            <div>
            <span id="cart" className="ms-auto cart-icon-header-span" onClick={()=>navigate('/Cart')}>
                <a className="nav-item text-light" style={{cursor:'pointer'}}><i className="fas fa-shopping-cart fa-lg"><sup id="cartnum1" className="badge bg-danger cart-icon-header" >{cartItemsCount}</sup></i></a>
            </span>
            </div>
        </div>
        </>
    )
}