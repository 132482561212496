import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PopUpContainer from '../../containers/popup'

export const OtherDetails = (props) => {
    const {productData}=props;
    return(
        <tbody>
            <tr>
                <td>Name</td>
                <td>{productData?.productname}</td>
            </tr>
            <tr>
                <td>Material</td>
                <td>{productData?.material}</td>
            </tr>
        </tbody>
    )
}
export const PotDetails = (props) => {
    const {productData}=props;
    return(
        <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{productData?.productname}</td>
                        </tr>
                        <tr>
                            <td>Material</td>
                            <td>{productData?.material}</td>
                        </tr>
                        <tr>
                            <td>Height</td>
                            <td>{productData?.potheight}</td>
                        </tr>
                        <tr>
                            <td>Width</td>
                            <td>{productData?.potwidth}</td>
                        </tr>
                    </tbody>
    )
}
export const ProductDetails=(props)=>{
    const { id } = useParams();
    useEffect(() => {
        document.title = 'Washingtonia || Product Details';
        async function fetchData() {
            const payload={
                id: id
            }
            await props.fetchProductData(payload);
        }
        fetchData();
      }, []);
    const {productData}=props;
    const imagePath=`../images/productpages/${productData?.imagename}`;
    return(
        <div class="container">
            <div class="row">
                <div id="proddetlsmob" class="col-md-12">
                <div>                        
                        <div class="col-md-12 bg-warning p-0 border product-details-image" onClick="productzoomin()">
                            <img className='product-details-image-zoom' src={imagePath}/>
                        </div>
                        <div class="col-md-3 mt-1">
                            <div class="ms-auto" style={{width: 100 + '%'}}>
                                <div class="productframesmall product-details-image-background" style={{ backgroundImage:`url(${imagePath})`}}>
                                </div>
                                <div class="productframesmall product-details-image-background" style={{ backgroundImage:`url(${imagePath})`}}>
                                </div>
                                <div class="productframesmall product-details-image-background" style={{ backgroundImage:`url(${imagePath})`}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="proddetls" class="col-md-6">
                    <div class="row">
                        <div class="col-md-3 mt-5 product-details-margin">
                            <div class="ms-auto" style={{width:90 + 'px'}}>
                                <div class="productframesmall product-details-image-background" style={{ backgroundImage:`url(${imagePath})`}}>
                                </div>
                                <div class="productframesmall product-details-image-background" style={{ backgroundImage:`url(${imagePath})`}}>
                                </div>
                                <div class="productframesmall product-details-image-background" style={{ backgroundImage:`url(${imagePath})`}}>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-md-9 bg-warning p-0 border product-details-image"  onClick="productzoomin()">
                            <img className='product-details-image-zoom' src={imagePath} />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-5">
                    <h3>{productData?.productname}</h3>
                    <h5 class="mt-2 mb-3 product-details-name">{productData?.category}</h5>
                    { productData?.instock === 'yes' ?
                        <>
                            <span className='product-stock'>in stock</span>
                            <div><PopUpContainer product={productData} source={1}></PopUpContainer></div>
                        </>
                    :
                    <span className='product-out-sock'>Out of stock</span>
                    }
                    <h5 class="mt-5">DESCRIPTION</h5>
                    <p className='product-details-description'>
                    A product description is a form of marketing copy used to describe and explain the benefits of your product. In other words, it provides all the information and details of your product on your ecommerce site. These product details can be one sentence, a short paragraph or bulleted
                    </p>                    
                </div>
            </div>
            <div class="container mt-5">            
                <table class="table table-striped border product-details-spec-border">
                    <thead>   
                        <tr style={{height:50 + 'px'}}>                           
                            <th className='about-washin-image-text'>SPECIFICATIONS</th>
                            <th></th>
                        </tr>
                    </thead>
                    {(productData?.category==='pot') ?
                        <PotDetails productData={productData}></PotDetails>
                    :(productData?.category==='other') ?
                        <OtherDetails productData={productData}></OtherDetails>
                    :
                    (<tbody>
                    <tr>
                        <td>Name</td>
                        <td>{productData?.productname}</td>
                    </tr>
                    <tr>
                        <td>Category</td>
                        <td>{productData?.category}</td>
                    </tr>
                    <tr>
                        <td>Sub Category</td>
                        <td>{productData?.subcategory}</td>
                    </tr>
                    <tr>
                        <td>Height</td>
                        <td>{productData?.height}</td>
                    </tr>
                    <tr>
                        <td>Width</td>
                        <td>{productData?.width}</td>
                    </tr>
                    <tr>
                        <td>Blooming</td>
                        <td>{productData?.blooming}</td>
                    </tr>
                    <tr>
                        <td>Fruits</td>
                        <td>{productData?.fruits}</td>
                    </tr>
                    <tr>
                        <td>Seasonal</td>
                        <td>{productData?.Seasonal}</td>
                    </tr>
                </tbody>)
                    }
                </table>
            </div>
        </div>

    )
}