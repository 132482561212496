import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Popup } from '../../components/Popup';
import { updateCart, addToCart } from '../cart/Actions';
import { makeSelectCartData } from '../cart/Selectors'

const mapStateToProps = createStructuredSelector({
    cartData: makeSelectCartData()
});
export function mapDispatchToProps(dispatch) {
    return {
        updateCart: () => dispatch(updateCart()),
        addToCart: (data)=> dispatch(addToCart(data)),
        dispatch,
    };
  }

const PopUpContainer = connect(mapStateToProps,mapDispatchToProps)(Popup);

export default PopUpContainer;