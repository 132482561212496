import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ProductDetails } from '../../components/Productdetails/Productdetails'
import { fetchProduct } from '../product/Actions';
import { makeSelectProductError, makeSelectProductData } from '../product/Selectors'

const mapStateToProps = createStructuredSelector({
    productData: makeSelectProductData(),
    productDataError: makeSelectProductError()
});
export function mapDispatchToProps(dispatch) {
    return {
        fetchProductData: (data) => dispatch(fetchProduct(data)),
      dispatch,
    };
  }

const ProductdetailsContainer = connect(mapStateToProps,mapDispatchToProps)(ProductDetails);

export default ProductdetailsContainer;