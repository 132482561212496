import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Sort } from '../../components/Sort';
import { sortProducts } from '../product/Actions';
import { makeSelectProductsData } from '../product/Selectors'

const mapStateToProps = createStructuredSelector({
    productsData: makeSelectProductsData()
});
export function mapDispatchToProps(dispatch) {
    return {
        sortProducts: (data) => dispatch(sortProducts(data)),
        dispatch,
    };
  }

const SortContainer = connect(mapStateToProps,mapDispatchToProps)(Sort);

export default SortContainer;