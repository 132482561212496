import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Search } from '../../components/header/Search';
import { searchProduct } from '../product/Actions';
import { makeSelectProductsError, makeSelectProductsData } from '../product/Selectors';
import { makeSelectCartData } from '../cart/Selectors';
import { updateCart } from '../cart/Actions';

const mapStateToProps = createStructuredSelector({
    productData: makeSelectProductsData(),
    productDataError: makeSelectProductsError(),
    cartData: makeSelectCartData()
});
export function mapDispatchToProps(dispatch) {
    return {
      searchProduct: (data) => dispatch(searchProduct(data)),
      updateCart: () => dispatch(updateCart()),
      dispatch,
    };
  }

const HeaderSearchContainer = connect(mapStateToProps,mapDispatchToProps)(Search);

export default HeaderSearchContainer;