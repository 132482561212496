import React, { useState, useEffect } from 'react';
export const ContainerSearch=(props)=>{
    const [searchParam, setSearchParam]=useState('');
    async function searchData() {
        const payload={
            searchKey:searchParam
        }
        await props.searchData(payload);
    }
    const searchlosebtn=()=>{
        return true;
    }
    return(
    <div id="searchcontainer" className="container-fluid bg-white container-search">
        <div className="container">
            <div className=" mt-4"><i className="menuresponse fa-solid fa-xmark fa-lg" onClick={searchlosebtn}></i></div>
            <div className="input-group mt-3 mb-2" style={{height:50 + 'px' }}>
                <input id="search" type="text" name="searchterms" value={searchParam}  onChange={e =>setSearchParam(e.target.value)} className="form-control me-1 ps-4 container-search-input" placeholder="Search" required/>
                <button type="button" onClick={()=>searchData()} className="menuresponsebtn btn" style={{ border:'none' }} >
                    SEARCH
                </button>
            </div>
        </div>
    </div>
    );

}