import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { updateCart } from '../containers/cart/Actions';

export const Popup=(props) =>{
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [emailError, setEmailError]=useState(false);
  const [email, setEmail]=useState('');
  const {source} = props;
  const handleSave = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(email);
    if(!isValid) {
        setEmailError(true);
        return;
    }
    const cachedItems= localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")):'';
    var oldItem=false;
    if(cachedItems.length > 0){
        cachedItems.find((o, i) => {
            if (o.product.id === props.product.id) {
                oldItem=true;
                cachedItems[i].quantity=cachedItems[i].quantity+1;
                return true; // stop searching
            }
        });
        if(!oldItem){
            const cartItem={
                product:props.product,
                quantity:1
            }
            cachedItems.push(cartItem)
        }
        localStorage.setItem("cartItems",JSON.stringify(cachedItems));
    }else{
        const cartItem=[{
            product:props.product,
            quantity:1
        }]
        localStorage.setItem("cartItems",JSON.stringify(cartItem));
    }
    const cartData={
        id:props.product.id,
        mailId:email
    }
    props.updateCart();
    props.addToCart(cartData);
    setShow(false);
    setEmail('');
  }
  return (
    <>
        {
            source===1 ?
            <div className='add-to-cart-large' onClick={handleShow}> Add To Cart</div>
            :
            <span onClick={handleShow} className='product-stock add-to-cart'>Add&nbsp;<i className="fa fa-shopping-cart" aria-hidden="true"></i></span>
        }
      
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <input className="form-control me-1 ps-4 search-input" value={email}  onChange={e =>setEmail(e.target.value)} type="text" placeholder='Please enter your email' required/>
            </div>
            {emailError ? <div className='error-message'>Please enter valid email address.</div>:''}
            <br></br>
            <div>
                <input className="form-control me-1 ps-4 search-input" type="text" placeholder='Please enter your phone number'/>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>Done</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}