import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { API_END_POINT } from '../Common';
import { FETCH_PRODUCTS, FETCH_PRODUCT, SEARCH_PRODUCT, SORT_PRODUCTS } from './Constants';
import { fetchProductsSuccess, fetchProductsError, fetchProductSuccess, fetchProductError } from './Actions';
import request, { requestGet, requestPost, headers } from '../../utils/request';
import { pot, outdoor, indoor } from './mock';
import { getData, postData } from '../../utils/api';
import { makeSelectProductsData } from './Selectors'
export function* fetchProucts(payload) {
    const { category, page } = payload.data;
    const DEFAULT_TIMEOUT = 40000;
    var requestURL = `${API_END_POINT}/products/?category=${category}`;
    if(page==='all'){
      requestURL = `${API_END_POINT}/all`;
    }else if(page==='category'){
      requestURL = `${API_END_POINT}/category/?subCategory=${category}`;
    }
    const mockResponse={
      pot: pot,
      outdoor: outdoor,
      indoor: indoor
    }
    try{
      const response = yield call(requestGet, requestURL, { method: "GET", mode: "no-cors", credentials: "omit", timeout: DEFAULT_TIMEOUT, headers });
      //const response = yield call(getData, requestURL);
      //const response = mockResponse[category];
      yield put(fetchProductsSuccess(response));
    } catch (error) {
      yield put(fetchProductsError(error));
    }
  }
  export function* fetchProuct(payload) {
    const DEFAULT_TIMEOUT = 40000;
    const { id } = payload.data;
    var requestURL = `${API_END_POINT}/product/?id=${id}`;
    try{
      const response = yield call(requestGet, requestURL, { method: "GET", mode: "no-cors", credentials: "omit", timeout: DEFAULT_TIMEOUT, headers });
      //const response = yield call(getData, requestURL)
      //const response = mockResponse[category];
      yield put(fetchProductSuccess(response));
    } catch (error) {
      yield put(fetchProductError(error));
    }
  }
  export function* searchProuct(payload) {
    const DEFAULT_TIMEOUT = 40000;
    const { data } = payload;
    var formdata = new FormData();
    formdata.append("searchKey", payload?.data?.searchKey);
    var requestURL = `${API_END_POINT}/search`;
    headers['Content-Type']= 'multipart/form-data';
    try{
      const response = yield call(postData, requestURL, data);
      //const response = yield call(request, requestURL, { method: 'POST', data, mode: "no-cors", credentials: "omit", referrerPolicy: 'no-referrer', timeout: DEFAULT_TIMEOUT, headers });
      //const response = yield call(requestPost, requestURL, { method: 'POST', data, mode: "no-cors", credentials: "omit", referrerPolicy: 'no-referrer',redirect: "follow", headers});
      //const response = mockResponse[category];
      yield put(fetchProductsSuccess(response));
    } catch (error) {
      yield put(fetchProductsError(error));
    }
  }
  export function* sortProducts(payload) {
    const { data } = payload;
    const { sortOrder, category } = data;
    const DEFAULT_TIMEOUT = 40000;
    var requestURL = `${API_END_POINT}/sort/?sortOrder=${sortOrder}&category=${category}`;
    try{
      const response = yield call(requestGet, requestURL, { method: "GET", mode: "no-cors", credentials: "omit", timeout: DEFAULT_TIMEOUT, headers });
      yield put(fetchProductsSuccess(response));
    } catch (error) {
      yield put(fetchProductsError(error));
    }
  }
  export function* productsDaemon() {
    yield takeLatest(FETCH_PRODUCTS, fetchProucts);
  }
  export function* productDaemon() {
    yield takeLatest(FETCH_PRODUCT, fetchProuct);
  }
  export function* searchDaemon() {
    yield takeLatest(SEARCH_PRODUCT, searchProuct);
  }
  export function* sortDaemon() {
    yield takeLatest(SORT_PRODUCTS, sortProducts);
  }
  export default function* productSaga() {
    yield all([productsDaemon(), productDaemon(),searchDaemon(),sortDaemon()])
  }