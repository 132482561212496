import React from 'react';
import { Link, useNavigate } from "react-router-dom";
export const Navbar=(props)=>{
    const navigate = useNavigate();
    return(
        <nav id="navbar2" className="navbar navbar-expand-sm navbar-light border-top">
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav d-flex justify-content-between" style={{width:80 + '%'}}>
                            <li className="nav-item">
                                <Link to="/" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">Home</div></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">About Us</div></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Indoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">Indoor Plants</div></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Outdoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">Outdoor Plants</div></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Pots" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">Pots</div></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Landscaping" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">Landscaping</div></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Otherproducts" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlink" className="nav-link">Other Products</div></Link>
                            </li>
                        </ul>
                            <div onClick={()=>navigate("/Projects")} id="navlink" className="nav-link ms-auto" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}>Our Projects</div>
                    </div>
                </nav>
    )
}