import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Categoriesall } from '../../components/categories/Categoriesall';
import { fetchProducts } from '../product/Actions';
import { makeSelectProductsError, makeSelectProductsData } from '../product/Selectors'

const mapStateToProps = createStructuredSelector({
    productData: makeSelectProductsData(),
    productDataError: makeSelectProductsError()
});
export function mapDispatchToProps(dispatch) {
    return {
        fetchProductsData: (data) => dispatch(fetchProducts(data)),
      dispatch,
    };
  }

const CategoriesAllContainer = connect(mapStateToProps,mapDispatchToProps)(Categoriesall);

export default CategoriesAllContainer;