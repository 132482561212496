
import React, { useState, useEffect } from 'react';
import { Filter } from './Filter';
import { Rows } from './Rows';
export const Services=(props)=>{
    
    useEffect(() => {
        document.title = 'Washingtonia || Services';
        
      }, []);
    const productsArray = props.productData ? props.productData : [] 
    return(
        <>
           <div id="topbanner" className="col-md-12 " style={{position:'relative'}}>
            <div className="col-md-12 inner-banner">
                <div id="top1zoom" className='services-banner-image'>
                    <div className="overlay"></div>
                </div>
                <div id="topbannerheadings">
                    <div className="col-md-8 text-center p-2 ps-4 pe-4">
                        <h3 className="inner-heading">SERVICES</h3>
                        <p style={{color:'white'}}>Green walls are a perfect solution if you want to transform a bare wall into a vibrant green oasis. Nieuwkoop Europe has so many options in all shapes and sizes, including indoors or outdoors, hydroponics or soil culture, modular or custom-made. We have the perfect solution for every space!</p>
                    </div>
                </div>
                <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>                             
                <div className="inner-offer">
                    <div id="topviewtab1" style={{cursor:'pointer'}}>
                        <h4 style={{color:'white'}}>GET THIS OFFER NOW</h4>
                        <div className="underlineaniation1"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="believe">
        <div className="container">
          <h2 className="heading2 mb-30 p-5 pb-0">
            Services we offer
          </h2>
          <div className="row">
          <div className="col-sm-6 col-lg-6 p-5 ">
            <div className="bg-white "><div className="item-animation"><img src="images/services/3.png" alt="" className="img-fluid card-img-top"/></div>
              <div className="p-4">
                <h5> <a href="#" className="text-dark">Landscaping works</a></h5>
                <p className="paragraph mb-10 pop-light">Maintains gardens and lawns, including mowing, trimming, pruning, raking, and weeding. Waters plants and lawns and adds fertilizer as needed. Installs outdoor lighting and sprinkler systems.</p>
                
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 p-5">
            <div className="bg-white "><div className="item-animation"><img src="images/services/4.png" alt="" className="img-fluid card-img-top"/></div>
              <div className="p-4">
                <h5> <a href="#" className="text-dark">Pot sales</a></h5>
                <p className="paragraph mb-10 pop-light">Maintains gardens and lawns, including mowing, trimming, pruning, raking, and weeding. Waters plants and lawns and adds fertilizer as needed. Installs outdoor lighting and sprinkler systems.</p>
                
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 p-5">
            <div className="bg-white "><div className="item-animation"><img src="images/services/1.png" alt="" className="img-fluid card-img-top"/></div>
              <div className="p-4">
                <h5> <a href="#" className="text-dark">Indoor plants</a></h5>
                <p className="paragraph mb-10 pop-light">Maintains gardens and lawns, including mowing, trimming, pruning, raking, and weeding. Waters plants and lawns and adds fertilizer as needed. Installs outdoor lighting and sprinkler systems.</p>
                
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 p-5">
            <div className="bg-white "><div className="item-animation"><img src="images/services/2.png" alt="" className="img-fluid card-img-top"/></div>
              <div className="p-4">
                <h5> <a href="#" className="text-dark">Outdoor plants</a></h5>
                <p className="paragraph mb-10 pop-light">Maintains gardens and lawns, including mowing, trimming, pruning, raking, and weeding. Waters plants and lawns and adds fertilizer as needed. Installs outdoor lighting and sprinkler systems.</p>
                
              </div>
            </div>
          </div>
        </div>
          <div className="square"></div>
        </div>
      </div>
        </>
    )
}