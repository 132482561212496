import { Layout } from './components/layout/Layout';
import './washingtoniafonts.css';
import './washingtoniastyle.css';

function App() {
  return (
      <Layout></Layout>
  );
}

export default App;
