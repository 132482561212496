import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Search } from '../../components/Search';
import { searchProduct } from '../product/Actions';
import { makeSelectProductsError, makeSelectProductsData } from '../product/Selectors'

const mapStateToProps = createStructuredSelector({
    productData: makeSelectProductsData(),
    productDataError: makeSelectProductsError()
});
export function mapDispatchToProps(dispatch) {
    return {
      searchProduct: (data) => dispatch(searchProduct(data)),
      dispatch,
    };
  }

const SearchContainer = connect(mapStateToProps,mapDispatchToProps)(Search);

export default SearchContainer;