import React from 'react';
import { Search } from './Search';
import HeaderSearchContainer from '../../containers/search/HeaderSearch';
import { Navbar } from './Navbar';
import { Mobile } from './Mobile';
import { Rolldown } from './Rolldown';
import { ContainerSearch } from './ContainerSearch';
export const Header=(props)=>{
    return(
        <>
            <Rolldown></Rolldown>
            <Mobile></Mobile>
            <div className="container-fluid" id="navbarbox">
                <div className="container">
                    <HeaderSearchContainer></HeaderSearchContainer>
                    <Navbar></Navbar>
                </div>
            </div>
            <ContainerSearch></ContainerSearch>
        </>
    )
}