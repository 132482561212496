import React from 'react';
import aboutImage from '../images/about.jpeg';
export const About=()=>{
    return(
        <>
            <div id="topbanner" class="col-md-12 " style={{position:'relative'}}>
            <div class="col-md-12 about-banner">
                <div id="top1zoom" className="about-banner-image">
                    <div class="overlay"></div>
                </div>
                <div id="topbannerheadings">
                    <div class="col-md-8 text-center p-2 ps-4 pe-4">
                        <h3 className="inner-heading">ABOUT US</h3>
                        <p style={{color:'white'}}>We have pleasure in introducing our company Washingtonian Nursery located in Qatar is a service oriented 
    and professionally managed company. We are one of the Premiums privately-owned nurseries in Qatar. Our 
    production facility currently cultivates more then 130-150 thousand plants (over 200-250 species) from 16-
    20 countries approximately. which are from China, Thailand, Malaysia, India, Pakistan, Holland, Italy, Spain, 
    Germany, Portugal, Costa Rica, Turkey, Greece, Argentina, Mexico, Brazil, and Chile. </p>
                    </div>
                </div>
                <i class="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>                                
                <div className="inner-offer">
                    <div id="topviewtab1" style={{cursor:'pointer'}}>
                        <h4 style={{color:'white'}}>GET THIS OFFER NOW</h4>
                        <div class="underlineaniation1"></div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <span class="p-4">
                <h3 class="pb-3 text-center pt-5">Our Mission</h3>
                <p class="text-center pb-4">
                To develop brands that inspire outdoor enthusiasts through exceptional service and product education.
                </p>
            </span>
            <div class="p-4 d-flex justify-content-center align-items-center text-light about-mission">
          <div class="col-md-9 text-center pt-4 pb-4 category-text-color pop-regular">"We hope for a possible co-operation and solicit your valuable enquiries to us. Further, we assure of our best attention in executing the order to your entire satisfaction in terms of quality and price. We are conscious that our 
organization is comparatively new for which each task is undertaken with a view to establishing long term relationship with the client. These basic values form a firm foundation for our growth. "</div>          
          </div>
          <div class="text-center about-washin-image-style">
            <img className="about-washin-image" src={aboutImage}/><br/><h5 className="about-washin-image-text">WASHINGTONIA</h5><h6 class="text-secondary about-washin-sub-text">COMPLETE GARDEN SOLUTION</h6>
          </div>
          <div class="m-4 d-flex justify-content-center align-items-center about-story-bg">
            <div class="col-md-8 p-4">
            <h2 class="text-center p-4">Our Story</h2>
            <p class="text-center pop-regular">
            Our staff boast many years of experience in Landscape industry and capable to delivering the goods to outmost 
customer satisfaction Each task is undertaken with a view to establishing and maintaining as effective long-term 
relationship. These basic values form a firm foundation for our success. We look forward to building a long-term 
business relationship and assure you of our quality services always. 
            </p>  
          </div>
          </div>
          <br/><br/>
        </div>
    </>
    )
}