import React from 'react';
import { useNavigate } from "react-router-dom";
export const Category=()=>{
    const navigate = useNavigate();
    const showCategory=(category)=>{
        navigate(`/Categories/${category}`)
    }
    return(
        <>
            <div style={{position:'relative'}}>
                <h1 className="pt-5 pb-3 text-center category-font">Categories</h1>
                <div className="container">
                    <div onClick={()=>showCategory('palms')} id="cat1">
                        <div className="category-image-container">
                            <div id="catimage1" className="category-image category-image1">
                                <div className="overlay"></div>
                            </div>
                            <div className="category-text"><h3 className="text-center" style={{color:'white'}}>PALMS</h3></div>
                        </div>
                    </div>
                    <div onClick={()=>showCategory('succulent')} id="cat2">
                        <div className="category-image-container">
                            <div id="catimage2" className="category-image category-image2">
                                <div className="overlay"></div>
                            </div>
                            <div className="category-text"><h3 className="text-center" style={{color:'white'}}>SUCCULENTS</h3></div>
                        </div>
                    </div>
                    <div onClick={()=>showCategory('otherplants')} id="cat3">
                        <div className="category-image-container">
                            <div id="catimage3" className="category-image category-image3">
                                <div className="overlay"></div>
                            </div>
                            <div className="category-text"><h3 className="text-center" style={{color:'white'}}>OTHER PLANTS</h3></div>
                        </div>
                    </div>
                    <div onClick={()=>showCategory('ornamental')} id="cat4">
                        <div className="category-image-container">
                            <div id="catimage4" className="category-image category-image4">
                                <div className="overlay"></div>
                            </div>
                            <div className="category-text"><h3 className="text-center" style={{color:'white'}}>ORNAMENTAL TREES</h3></div>
                        </div>
                    </div>
                    <div onClick={()=>showCategory('shrubs')} id="cat5">
                        <div className="category-image-container">
                            <div id="catimage5" className="category-image category-image5">
                                <div className="overlay"></div>
                            </div>
                            <div className="category-text"><h3 className="text-center" style={{color:'white'}}>SHRUBS</h3></div>
                        </div>
                    </div>
                    <div onClick={()=>navigate('/Categoriesall')} id="cat6">
                        <div className="border category-image-container">
                            <div className="category-text"><div className="btn btn-warning rounded" style={{color:'white'}}>View All</div></div>
                        </div>
                    </div>                     
                </div>
        </div>
        </>
    )
}