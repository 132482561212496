import {
    UPDATE_CART, ADD_TO_CART
} from './Constants';

export function updateCart(data) {
    return {
      type: UPDATE_CART,
      data: data,
    };
  }
  export function addToCart(data) {
    return {
      type: ADD_TO_CART,
      data: data,
    };
  }