
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export const Landscaping=(props)=>{
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Washingtonia || Landscaping';
      }, []);
    return(
        <>
            <div id="topbanner" className="col-md-12 " style={{position:'relative'}}>
                <div className="col-md-12 inner-banner">
                    <div id="top1zoom" className='landscape-banner-image'>
                        <div className="overlay"></div>
                    </div>
                    <div id="topbannerheadings">
                        <div className="col-md-8 text-center p-2 ps-4 pe-4">
                            <h3 className="inner-heading">LANDSCAPING</h3>
                            <p style={{color:'white'}}>Maintains gardens and lawns, including mowing, trimming, pruning, raking, and weeding. Waters plants and lawns and adds fertilizer as needed. Installs outdoor lighting and sprinkler systems</p>
                        </div>
                    </div>
                    <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>
                    <div className="inner-offer">
                        <div id="topviewtab1" style={{cursor:'pointer'}}>
                            <h4 style={{color:'white'}}>GET THIS OFFER NOW</h4>
                            <div className="underlineaniation1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <h3 className="pt-5 mb-4"><span className='landscape-title'>LANDSCAPING & MAINTENANCE</span></h3>
                <p className='landscape-text'>
                Landscapes are beautiful areas where you can relax and enjoy the benevolent nature. In order to sustain our clients' satisfaction, we at Greenstar Landscape provide reliable Landscape Maintenance at an affordable price.
                </p>
                <p className='landscape-text'>
                The designing, maintenance and installation services are continuously supervised by our experts so as to ensure that specifications of the customers are duly met. To us, maintenance quality comes first and cannot be sacrificed at any point.
                </p>
                <p className='landscape-text'>
                To add, the tools which we use while rendering these services are made of the best quality of materials, procured from certified vendors in the market.
                </p>
                <div className="p-0">
                    <div className="landscape col-md-3 bg-success">
                        <div className="md-12 landscape-image1">
                        </div>
                    </div>
                    <div className="landscape col-md-3 bg-success">
                        <div className="md-12 landscape-image2">
                        </div>
                    </div>
                    <div className="landscape col-md-3 bg-success">
                        <div className="md-12 landscape-image3">
                        </div>
                    </div>
                    <div className="landscape col-md-3 bg-success">
                        <div className="md-12 landscape-image4">
                        </div>
                    </div>
                </div>
                <span onClick={()=>navigate("/Landscapingwork")} className="btn btn-warning mb-5 mt-3 landscape-viewall">View our work</span>
            </div>

        </>
    )
}