import {
    ADD_TO_CART
} from './Constants';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { API_END_POINT } from '../Common';
import { fetchProductsSuccess, fetchProductsError, fetchProductSuccess, fetchProductError } from './Actions';
import request, { requestGet, requestPost, headers } from '../../utils/request';

export function* addCart(payload) {
    const { data } = payload;
    const {id, mailId } = data;
    const DEFAULT_TIMEOUT = 40000;
    var requestURL = `${API_END_POINT}/mail/?id=${id}&mailId=${mailId}`;
    try{
        const response = yield call(requestGet, requestURL, { method: "GET", mode: "no-cors", credentials: "omit", timeout: DEFAULT_TIMEOUT, headers });
      } catch (error) {
       console.log('mailError',error);
      }
  }
export function* cartDaemon() {
    yield takeLatest(ADD_TO_CART, addCart);
  }
  
  export default function* cartSaga() {
    yield all([cartDaemon()])
  }