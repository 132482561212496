
import React, { useEffect } from 'react';

export const Privacy=(props)=>{
    useEffect(() => {
        document.title = 'Washingtonia || About us';
      }, []);
    return(
        <div>
       <h2 className="text-center p-4 mt-4">Privacy Policy</h2>

          <div className="d-flex justify-content-center align-items-center about-story-bg">
            <div className="col-md-8 p-4">
            <p className='pop-regular'>
            We respect your privacy
We use cookies and other technologies on our website. Some of them are essential, while others help us to improve this website and your experience. Personal data may be processed (e.g. IP addresses), for example for personalized ads and content or ad and content measurement. You can find more information about the use of your data in our privacy policy.

Here you will find an overview of all cookies used. You can give your consent to whole categories or display further information and select certain cookies.

If you are under 16 and wish to give consent to optional services, you must ask your legal guardians for permission.
            </p>
          </div>
          </div>
          <br></br><br></br>
        </div>
    )
}