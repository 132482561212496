import React from 'react';
import { useNavigate } from "react-router-dom";
export const Content=()=>{
    const navigate = useNavigate();
    return(
        <div className="container pt-5">
            <div className="row">
                <div className="col-md-5 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <h1 className="pt-5 pb-4 p-4 pop-bold">
                            25% discount on all plant displays!
                        </h1>
                        <p className="pop-light content-align">
                            Are you already familiar with our plant displays? These are unique plant-pot combinations that have been put together for you, all according to the latest trends. You will currently find more than 2,500 plant displays in our range and you can now order these with a 25% discount each! Order your discounted plant display directly on our website and we will start assembling it for you. Your plant-pot combination will be delivered fully prepared at your chosen location.
                        </p>
                        <h5 className="pt-4 pop-bold">Take advantage of the offer and shop your plant display now!</h5>
                        <div className="btn rounded-pill mt-5 ps-4 pe-4 content-title" onClick={()=>navigate('/Services')}>VIEW ALL PLANT DISPLAYS</div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="p-3 d-flex justify-content-center">
                                <div className="content-image1">                        
                                </div>
                            </div>
                            <div className="p-3 d-flex justify-content-center">
                                <div className="content-image2">                        
                                </div>
                            </div>                    
                        </div>
                        <div className="col-md-6" style={{height:700 + 'px'}}>
                            <div className="d-flex justify-content-center align-items-center content-image3-frame content-image3-frame">
                                <div className="content-image3">                        
                                </div>
                            </div>                                     
                        </div>
                    </div> 
                </div>             
            </div>
        </div>
    )
}