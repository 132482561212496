import { createSelector } from 'reselect';

const productReducer = (state) => state.productReducer;

export const makeSelectProductsError = () => createSelector(
    productReducer,
  (productsData) => {
	return productsData.fetchProductsError;
  }
);
export const makeSelectProductsData = () => createSelector(
    productReducer,
  (productsData) => {
	return productsData.fetchProductsData;
  }
);
export const makeSelectProductError = () => createSelector(
  productReducer,
(productData) => {
return productData.fetchProductError;
}
);
export const makeSelectProductData = () => createSelector(
  productReducer,
(productData) => {
return productData.fetchProductData[0];
}
);