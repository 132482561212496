
import React, { useEffect, useState } from 'react';

export const Sort=(props)=>{
    const {sortProducts, productsData, category }=props;
    const sort = (order) => {
        const payload = {
            sortOrder: order,
            category: category
        }
        sortProducts(payload);
    }
    return(
        <div className="ms-auto" id="sortheading">SORT: <span id="rf" onClick={()=>sort('rf')} style={{cursor:'pointer'}} className="ps-3">Recent First <i className="fa-solid fa-sort-up"></i></span> <span id="of" onClick={()=>sort('of')} style={{cursor:'pointer'}} className="ps-3">Oldest First <i className="fa-solid fa-sort-down"></i></span> </div>
    )
}