import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../images/logo.png';
export const Mobile=()=>{
    const menuOpen=()=>{
        document.getElementById("mobmenucontainer").style.marginLeft="0px";
    }
    const menuClose=()=>{
        document.getElementById("mobmenucontainer").style.marginLeft="-100%";
    }
    const searchOpenBtn=()=>{
        document.getElementById("searchcontainer").style.top="0px";
        document.getElementById("mobmenucontainer").style.marginLeft="-100%";
        document.getElementById("rolldownmenucontainer").style.marginLeft="-100%";
    }
    return(
        <>
            <div id="mobmenucontainer" className="d-flex">
                <div className="mobile-menu">
                </div>
                <div style={{width:80 + '%'}}>
                    <ul className="navbar-nav ps-3 pt-5">
                        <li className="nav-item">
                            <Link to="/" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link" >Home</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/About" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">About Us</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Indoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Indoor Plants</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Outdoor" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Outdoor Plants</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Pots" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Pots</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Landscaping" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Landscaping</div></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Otherproducts" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div id="navlinkmob" className="nav-link">Projects</div></Link>
                        </li>
                        <li onClick={menuClose} className="menuresponse bg-warning p-1 text-center mt-5" style={{width:100 + 'px'}}>
                            CLOSE
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container-fluid p-3 align-items-center justify-content-between" id="navbarboxmob">
                <div className="nav-item">
                    <i onClick={menuOpen} className="menuresponse fa-solid fa-bars fa-2xl"></i>
                </div>
                <div>
                    <Link to="/" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><img src={logo} className="logo-style"/></Link>
                </div>
                <div>
                    <i onClick={searchOpenBtn} className="menuresponse fa-sharp fa-solid fa-magnifying-glass fa-xl"></i>
                </div>
            </div>
        </>
    )
}