import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCT,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_ERROR,
    SEARCH_PRODUCT
} from './Constants';
const initialState = {
    fetchProducts:'',
    fetchProductsData:'',
    fetchProductsError:'',
    fetchProduct:'',
    fetchProductData:'',
    fetchProductError:'',
    searchProduct:''
}
export function productReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_PRODUCTS:
        return {
          ...state,
       };
      case FETCH_PRODUCTS_SUCCESS:
        return {
          ...state,
          fetchProductsData: action.data
       };
      case FETCH_PRODUCTS_ERROR:
        return {
          ...state,
          fetchProductsError: 'Some error occured, please contact our customer spport'
       };
       case FETCH_PRODUCT:
        return {
          ...state,
       };
      case FETCH_PRODUCT_SUCCESS:
        return {
          ...state,
          fetchProductData: action.data
       };
      case FETCH_PRODUCT_ERROR:
        return {
          ...state,
          fetchProductError: 'Some error occured, please contact our customer spport'
       };
      case SEARCH_PRODUCT:
        return {
          ...state,
       };
      default:
        return state;
    }
}