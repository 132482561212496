import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware,  ConnectedRouter } from 'connected-react-router';
import { BrowserRouter, HashRouter } from "react-router-dom"
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers/index';
import rootSaga from './sagas/index';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
var composeEnhancers = '';
if(process.env.NODE_ENV && process.env.NODE_ENV.trim() !== "production"){
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}else{
  composeEnhancers = compose;
}
const configureStore=(preloadedState)=>{
    const store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      composeEnhancers(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          sagaMiddleware
          // ... other middlewares ...
        ),
      ),
    )
    return store;
  }
  //const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  const store = configureStore();
  sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <HashRouter history={history}>
            <App />
        </HashRouter>
    </Provider>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
