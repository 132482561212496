import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Cart } from '../../components/Cart';
import { updateCart } from '../cart/Actions';
import { makeSelectCartData } from '../cart/Selectors'

const mapStateToProps = createStructuredSelector({
    cartData: makeSelectCartData()
});
export function mapDispatchToProps(dispatch) {
    return {
        updateCart: () => dispatch(updateCart()),
        dispatch,
    };
  }

const CartContainer = connect(mapStateToProps,mapDispatchToProps)(Cart);

export default CartContainer;