
import React, { useState, useEffect } from 'react';
const Items=(props)=>{
    const {product}=props;
    const imagePath=`/images/projects/${product.projectimage1}`;
    return(
        <div class="col-sm-6 col-lg-6 p-5 ">
              <div class="bg-white "><div class="item-animation"><img src={imagePath} alt="" class="img-fluid card-img-top"/></div>
                <div class="p-4">
                  <h5> <a href={product.website} target="_blank" class="text-dark">{product.projecttitle}</a></h5>
                  <p class="paragraph mb-10 pop-light">{product.description}</p>                  
                </div>
              </div>
        </div>
    )
}
export const Projects=(props)=>{
    useEffect(() => {
        document.title = 'Washingtonia || Projects';
        async function fetchData() {
            const payload={
                category:'projects'
            }
            await props.fetchProductsData(payload);
        }
        fetchData();
      }, []);
    const productsArray = props.productData ? props.productData : [] 
    return(
        <>
            <div id="topbanner" className="col-md-12 " style={{position:'relative'}}>
                <div className="col-md-12 inner-banner">
                    <div id="top1zoom" className='projects-banner-image'>
                        <div className="overlay"></div>
                    </div>
                    <div id="topbannerheadings">
                        <div className="col-md-8 text-center p-2 ps-4 pe-4">
                            <h3 className="inner-heading">PROJECTS</h3>
                            <p style={{color:'white'}}>Green walls are a perfect solution if you want to transform a bare wall into a vibrant green oasis. Nieuwkoop Europe has so many options in all shapes and sizes, including indoors or outdoors, hydroponics or soil culture, modular or custom-made. We have the perfect solution for every space!</p>
                        </div>
                    </div>
                    <i className="fab fa-pagelines fa-lg mt-4 me-2 float-right inner-icon" aria-hidden="true"></i>
                    <div className="inner-offer">
                        <div id="topviewtab1" style={{cursor:'pointer'}}>
                            <h4 style={{color:'white'}}>GET THIS OFFER NOW</h4>
                            <div className="underlineaniation1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="believe">
                <div class="container">
                    <h2 class="heading2 mb-30 p-5 pb-0">
                        PROJECTS
                    </h2>
                    <div class="row">
                        { productsArray?.length > 0 ?
                                productsArray.map((product, index) => {
                                    return(
                                <Items product={product} index={index}></Items>
                                )})
                                :
                                ''
                        }
                    </div>
                    <div class="square"></div>
                </div>
            </div>

        </>
    )
}